import "./MainPage.css";
import LeafletMap from '../Components/LeafletMap/LeafletMap'
// import UpdatingPolyLine from '../Components/TestMaps/UpdatingPolyLine';
// import MapInstanceProperty from '../Components/TestMaps/mapInstanceProperty';
// import AddEffectByCode from '../Components/TestMaps/AddEffectByCode';

export default function MainPage(){
    return <div className='MainPage'>
        <LeafletMap />
        {/* <UpdatingPolyLine /> */}
        {/* <MapInstanceProperty /> */}
        {/* <AddEffectByCode /> */}
    </div>
}