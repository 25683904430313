import React, {
  useEffect,  useState, useCallback /*, useRef, useMemo, useDebugValue */,
} from "react";
import "./LeafletMap.css";
import {
  MapContainer,
  TileLayer,
  Polyline,
  // useMap
} from "react-leaflet";
import { getPaths } from '../../data/paths';
// import L from 'leaflet';

export default function LeafletMap(props) {
  const [lng, /*setLng*/] = useState(15.288221000000002); //12.10000);
  const [lat, /*setLat*/] = useState(59.89521199999999); //57.69500);
  const [zoom /*setZoom*/] = useState(13);
  const position = [lat, lng];
  const [currentlySelectedPath, setcurrentlySelectedPath] = useState(null);
  const [associatedPaths, setAssociatedPaths] = useState(null);
  const [paths, setPaths] = useState([]);
  const [loading, setLoading] = useState(false);
  const [initialLoadDone, setInitialLoadDone] = useState(false);
  // const [/*buttonClicked*/ setbuttonClicked] = useState(false);
  // const [clickedPath, setClickedPath] = useState(false);
  const [map, setMap] = useState(null);

  useEffect(() => {
    if(paths.length > 0) setLoading(false)
  }, [paths]);

  const searchPaths = useCallback(() => {
    if(!map || loading){
      return;
    }

    setLoading(true)

    var bounds = map.getBounds();
    var westLonX = bounds.getWest();
    var northLatY = bounds.getNorth();
    var eastLonX = bounds.getEast();
    var southLatY = bounds.getSouth();

    getPaths(westLonX, northLatY, eastLonX, southLatY) // getPaths(-80, 80, 80, -80)
      .then(response => {
        var pathsToBeAdded = []
        var existingPathIds = paths.map((path) => path.id)
        
        if(response.response.length > 0){
          response.response.forEach((newPath) => {
            var index = existingPathIds.indexOf(newPath.id)
            if(index === -1) pathsToBeAdded = pathsToBeAdded.concat(newPath)
          })
        }

        setPaths(paths.concat(pathsToBeAdded))
        setLoading(false)
      })
  }, [map, loading, paths])

  useEffect(() => {
      if(!initialLoadDone){
        setInitialLoadDone(true)
        searchPaths();
      }
  }, [initialLoadDone, searchPaths])

  // function updatePaths(newPaths){
  //   console.log("current paths length: " + paths.length + ", new paths length: " + newPaths.length)
    
  //   if(paths.lenth !== newPaths.length)
  //     setPaths(...paths, newPaths)

  //   paths.forEach((existingPath) => console.log(existingPath.name))
  //   var currentPathNames = paths.map((path) => path.name);

  //   newPaths.forEach((newPath) => {
  //     if(!currentPathNames.includes(newPath.name)){
  //       console.log("new path found! Adding " + newPath.name)
  //       setPaths(...paths, newPath)
  //     }
  //   });

  //   console.log("number of paths: " + paths.length)
  // }

  function selectPath(path, entity) {
    entity.target.setStyle({ color: "white", dashArray: null });
    
    setcurrentlySelectedPath(path);
    // setClickedPath(true);
    
    // console.log(`Type: ${path.Type}, getting there: ${path.GettingThere}, logic: ${path.GettingThere !== undefined}`)

    // if (path.Type === "trail"){
    //   if(path.GettingThere !== undefined)
    //     setAssociatedPaths(path.GettingThere);
    //   else setAssociatedPaths(null);
    // }

    // map.eachLayer((layer) => {
    //   if (layer instanceof L.Polyline) {
    //     console.log(layer._leaflet_id)
    //     console.log(JSON.stringify(layer, replacer))//JSON.parse(JSON.stringify(layer)))
    //     map.removeLayer(layer);
    //   }
    // });

    // paths.forEach((path) => {
    //   var polyLine = L.polyline(path.geometry.coordinates, { id: "orangutang", color: "red", weight: 6, asdasd:"asd" })
    //   polyLine.id = path.Id
    //   path.leafletId = polyLine._leaflet_id
    //   polyLine.addTo(map);
    // })
  }

  function clearPath() {
    const updatedPaths = paths.map(updatingPolyLine => {
      if(updatingPolyLine.Id === currentlySelectedPath.Id){
        return { ...updatingPolyLine, ...{ Color: currentlySelectedPath.OriginalColor }};
      }

      return updatingPolyLine;
    })

    setPaths(updatedPaths);
    
    setcurrentlySelectedPath(null);
    setAssociatedPaths(null);
  }

  function getTextForDifficulty(difficultyLevel){
    switch(difficultyLevel) {
      // case 0 : return "Very easy"
      case 1 : return "Easy"
      case 2 : return "Medium"
      case 3 : return "Hard"
      default: return "Unkown"
    }
  }

  const onMoveEnd = useCallback(() => {
    searchPaths();
  }, [searchPaths])

  useEffect(() => {
    if(!map) return

    map.on('moveend', onMoveEnd)
    return () => {
      map.off('moveend', onMoveEnd)
    }
  }, [map, onMoveEnd])

  return (
    <>
      {/* {map && <p> latitude: {map.getCenter().lat.toFixed(4)}, longitude: {map.getCenter().lng.toFixed(4)} </p> } */}
      
      {
      // !loading && 
      <MapContainer  ref={setMap} center={position} zoom={zoom} scrollWheelZoom={true} >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />

          {!loading && paths.map((path) => {
            var isTheSelectedPath = currentlySelectedPath && currentlySelectedPath.id === path.id

            // dashed array looks very messy when zoomed out. Maybe base it on zoom level?
            // var dashArraySettings = isTheSelectedPath || !path.parentHasMultipleStages ? null : path.stageNumber % 2 === 0 ? '40, 20' : null; // [dash, gap], null to turn off. https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/stroke-dasharray 
            var dashArraySettings = null; // [dash, gap], null to turn off. https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/stroke-dasharray 

            return (
              <Polyline
              key={path.id}
              positions={path.geometry.coordinates.map((item) => {
                return [item[1], item[0]];
              })}
              pathOptions={{ 
                color: isTheSelectedPath ? "white" : "orange",
                weight: 12, 
                dashArray: dashArraySettings
              }}
              eventHandlers={{
                click: (e) => {
                  selectPath(path, e);
                },
                mouseover: (e) => {
                  e.target.setStyle({ weight: 18 });
                  e.target.setStyle({ color: "white" });
                },
                mouseout: (e) => {
                  e.target.setStyle({ weight: 12 });
                  e.target.setStyle({ color: isTheSelectedPath ? "white" : "orange" });
                },
              }}
            />
            )})}

          {!loading && associatedPaths &&
          associatedPaths.map((path) => (
            <Polyline
              key={path.Name}
              positions={path.geometry.coordinates}
              pathOptions={path.pathOptions}
              eventHandlers={{
                click: () => {
                  selectPath(path);
                },
                mouseover: (e) => {
                  e.target.setStyle({ weight: 12 });
                },
                mouseout: (e) => {
                  e.target.setStyle({ weight: 6 });
                },
              }}
            />
          ))}

        </MapContainer>
      }
      
      {!currentlySelectedPath && (
        <div id="noPathSelected">
          <p>Click on a path to see information about it</p>
        </div>
      )}

      {currentlySelectedPath && (
        <div id="pathInfo">
          <section>
          {/* <picture>
            <source srcset="https://images.alltrails.com/eyJidWNrZXQiOiJhc3NldHMuYWxsdHJhaWxzLmNvbSIsImtleSI6InVwbG9hZHMvcGhvdG8vaW1hZ2UvNDk5OTQ0NDcvYTM4YzYxOWY4NmMzMzQ3YWQyNzU1ZDRmZWY0MzdlZTcuanBnIiwiZWRpdHMiOnsidG9Gb3JtYXQiOiJ3ZWJwIiwicmVzaXplIjp7IndpZHRoIjoyMDQ0LCJoZWlnaHQiOjYyNCwiZml0IjoiY292ZXIifSwicm90YXRlIjpudWxsfX0=" type="image/webp" />
            <img src="https://bergslagsledencdnendpoint.azureedge.net/media/su5jrybu/ny-baenk_smaatjaernarna.jpg?width=1200&height=600&rnd=133543028426170000"></img>
          </picture> */}
          {/* <img src="https://bergslagsledencdnendpoint.azureedge.net/media/su5jrybu/ny-baenk_smaatjaernarna.jpg?width=1200&height=600&rnd=133543028426170000"></img> */}
          {currentlySelectedPath.parent && (<h4 className="parentName">{currentlySelectedPath.parent}</h4>)}
          <h3 className="pathName">{currentlySelectedPath.name} {currentlySelectedPath.parentHasMultipleStages && "(" + currentlySelectedPath.stageNumber + "/" + currentlySelectedPath.totalStages + ")"}</h3>
          </section>

          <section className="pathDetailSection">
            <div className="pathDetail">
              <div className="icon">
                <svg className="pathIcon" focusable="false" aria-hidden="true" viewBox="0 0 24 24">
                  <path d="M19 15.18V7c0-2.21-1.79-4-4-4s-4 1.79-4 4v10c0 1.1-.9 2-2 2s-2-.9-2-2V8.82C8.16 8.4 9 7.3 9 6c0-1.66-1.34-3-3-3S3 4.34 3 6c0 1.3.84 2.4 2 2.82V17c0 2.21 1.79 4 4 4s4-1.79 4-4V7c0-1.1.9-2 2-2s2 .9 2 2v8.18c-1.16.41-2 1.51-2 2.82 0 1.66 1.34 3 3 3s3-1.34 3-3c0-1.3-.84-2.4-2-2.82zM6 7c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm12 12c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1z" />
                </svg>
              </div>
              <div>{currentlySelectedPath.lengthInKm} km</div>
            </div>

            <div className="pathDetail">
              <div className="icon">
                <svg className="pathIcon" focusable="false" aria-hidden="true" viewBox="0 0 24 24">
                <path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm.5-13H11v6l5.25 3.15.75-1.23-4.5-2.67z"/>
                </svg>
              </div>
              <div>{currentlySelectedPath.duration} h</div>
            </div>

            <div className="pathDetail">
              <div className="icon">
                <svg className="pathIcon" focusable="false" aria-hidden="true" viewBox="0 0 24 24">
                <path d="M13.5 5.5c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm4 5.28c-1.23-.37-2.22-1.17-2.8-2.18l-1-1.6c-.41-.65-1.11-1-1.84-1-.78 0-1.59.5-1.78 1.44S7 23 7 23h2.1l1.8-8 2.1 2v6h2v-7.5l-2.1-2 .6-3c1 1.15 2.41 2.01 4 2.34V23H19V9h-1.5v1.78zM7.43 13.13l-2.12-.41c-.54-.11-.9-.63-.79-1.17l.76-3.93c.21-1.08 1.26-1.79 2.34-1.58l1.16.23-1.35 6.86z" />
                </svg>
              </div>
              <div>{getTextForDifficulty(currentlySelectedPath.difficulty)}</div>
            </div>
          </section>

          <section>
            <button>More details</button><br />
            <button onClick={clearPath}>Unselect path</button>&nbsp;<br /><br />
          </section>

        </div>
        )}
    </>
  );
}
